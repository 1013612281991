import { Button, Card, CardBody, CardFooter, CardHeader, Input, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { UserInterface } from "../../../interfaces/UserInterface";
import Shop from "../../../services/API/clients/ClientAPI";
import { toast } from "react-toastify";
import { Storage } from "../../../services/storage";
import AsyncSelect from "react-select/async";
import handleErrorResponse from "../../../hooks/HandleErrors";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import axios from "axios";
import { fetchSuggestions } from "../../../common/address-autocompletion";
import { useNavigate } from "react-router-dom";

export default function Profil() {
  const navigate = useNavigate();
  const [actualUser, setActualUser] = useState<UserInterface>({
    email: "",
    password: "",
    name: "",
    address: "",
    phoneNumber: "",
    latitude: 0,
    longitude: 0,
    pointPerPricePercent: 0,
  });
  const [newPassword, setNewPassword] = useState("");

  const handleChange = (e: any) => {
    setActualUser({
      ...actualUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeInt = (e: any) => {
    let value = Number.parseInt(e.target.value);

    if (Number.isNaN(value)) {
      value = 0;
    }
    
    setActualUser({
      ...actualUser,
      [e.target.name]: value,
    });
  }

  const handleEditUser = async () => {
    const datas = {
      email: actualUser.email,
      password: actualUser.password,
      name: actualUser.name,
      address: actualUser.address,
      phoneNumber: actualUser.phoneNumber,
      latitude: actualUser.latitude,
      longitude: actualUser.longitude,
      pointPerPricePercent: actualUser.pointPerPricePercent,
      reductionOfferByPoint: [],
    };
    const response = await Shop.edit(datas);
    if (response.status === 200 || response.status === 201) {
      toast.success("User edited successfully");
    } else {
      handleErrorResponse(response);
    }
  };

  useEffect(() => {
    const fetchDataUser = async () => {
      const response = await Shop.user();
      if (response.status === 200 || response.status === 201) {
        setActualUser(response.body);
      } else {
        handleErrorResponse(response);
      }
    };
    fetchDataUser();
  }, []);

  const handleDeleteUser = async () => {
    const response = await Shop.deleting();
    if (response.status === 204) {
      Storage.emptyStorage();
      toast.success("User deleted successfully");
      navigate("/");
    } else {
      handleErrorResponse(response);
    }
  };

  const promiseOptions = async (inputValue: string) => {
    const response = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${inputValue}&limit=5`);
    const data = await response.json();
    return data.features.map((feature: any) => {
      return {
        label: feature.properties.label,
        value: feature.geometry.coordinates,
      };
    });
  };

  return (
    <Card onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="w-full mt-12 shadow-none border-2 border-black rounded-sm">
      <CardHeader onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="gradient" color="purple" className="flex justify-between items-center px-8 h-16 place-items-center rounded-sm">
        <span></span>
        <Typography onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="h3" color="white">
          Profil
        </Typography>
        <span></span>
      </CardHeader>
      <CardBody onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="flex flex-col gap-4">
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.email} onChange={handleChange} name="email" crossOrigin="" color="purple" label="Email" size="lg" disabled />
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.name} onChange={handleChange} name="name" crossOrigin="" color="purple" label="Name" size="lg" />
        {/* <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.address} onChange={handleChange} name="address" crossOrigin="" color="purple" label="Address" size="lg" /> */}
        <label className="text-xs font-regular text-blue-gray-400 ml-2.5 px-1 w-fit -mt-2 -mb-6 bg-white relative z-10">Adresse</label>
        <AsyncSelect
          className="w-full h-10 border border-black rounded pl-8"
          placeholder={actualUser.address}
          loadOptions={fetchSuggestions}
          cacheOptions
          defaultOptions
          onChange={(selectedOption: any) => setActualUser({
            ...actualUser,
            address: selectedOption.label,
            longitude: selectedOption.longitude,
            latitude: selectedOption.latitude
          })}
          styles={{
            control: (baseStyles: any, state: { isFocused: boolean }) => ({
              ...baseStyles,
              height: "100%",
              border: "none",
              borderColor: state.isFocused ? "transparent" : "none",
              boxShadow: state.isFocused ? "0 0 0 1px transparent" : "none",
              paddingLeft: "0px"
            }),
            // remove the default border radius
            input: (styles) => ({ ...styles, height: "100%", border: "none" }),
            menu: (styles) => ({ ...styles, zIndex: 9999 }),
          }}
          menuPortalTarget={document.body}
        />
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.phoneNumber} onChange={handleChange} name="phoneNumber" crossOrigin="" color="purple" label="Phone Number" size="lg" />
        <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.pointPerPricePercent} onChange={handleChangeInt} name="pointPerPricePercent" crossOrigin="" color="purple" label="Pourcentage prix d'achat convertis en points" size="lg" />
        <Input type="password" onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={actualUser.password} onChange={handleChange} name="password" crossOrigin="" color="purple" label="Mot de passe" size="lg" />
        <Input type="password" onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} crossOrigin="" color="purple" label="Nouveau mot de passe" size="lg" />
      </CardBody>
      <CardFooter onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="pt-0 flex flex-col gap-4">
        <Button onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} onClick={() => handleEditUser()} placeholder="" color="purple" variant="outlined" className="rounded-sm" fullWidth>
          Save modifications
        </Button>
        <Button onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} onClick={() => handleDeleteUser()} placeholder="" color="red" variant="outlined" className="rounded-sm" fullWidth>
          Delete account
        </Button>
      </CardFooter>
    </Card>
  );
}
