import { useEffect, useState } from "react";
import ModalProduct from "../../../components/Modals/ModalProduct";
import { Card, CardBody, CardHeader, Input, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductsAPI from "../../../services/API/clients/ProductsAPI";
import { ProductsInterface } from "../../../interfaces/ProductsInterface";
import handleErrorResponse from "../../../hooks/HandleErrors";

export default function Tickets() {
    const [offers, setOffers] = useState<ProductsInterface[]>([]);
    const [idToEdit, setIdToEdit] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);

    const fetchDataFidelite = async () => {
        const response = await ProductsAPI.products();
        if (response.status === 200 || response.status === 201) {
            setOffers(response.body);
        } else {
            handleErrorResponse(response);
        }
    };

    useEffect(() => {
        fetchDataFidelite();
    }, []);

    const handleEdit = async (id: string) => {
        setIdToEdit(id);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        fetchDataFidelite();
        setIdToEdit("");
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            fetchDataFidelite();
        } else {
            const dataFiltered = offers.filter((offer) => offer.name.toLowerCase().includes(e.target.value.toLowerCase()) || offer.description.toLowerCase().includes(e.target.value.toLowerCase()));
            setOffers(dataFiltered);
        }
    };

    return (
        <>
            <ModalProduct open={openModal} setOpen={handleCloseModal} idToEdit={idToEdit} />
            <Card onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="w-full mt-12 shadow-none border-2 border-black rounded-sm">
                <CardHeader onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="gradient" color="purple" className="flex justify-between items-center px-8 h-16 place-items-center rounded-sm">
                    <span></span>
                    <Typography onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="h3" color="white">
                        Produits
                    </Typography>
                    <button className="bg-white text-purple-500 px-4 py-2 rounded-lg" onClick={() => setOpenModal(true)}>
                        Nouveau
                    </button>
                </CardHeader>
                <CardBody onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="flex flex-col gap-4">
                    <Input onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} crossOrigin="" label="Rechercher" placeholder="Rechercher" color="purple" onChange={handleChange} />
                    {offers.length === 0 
                        ? (
                            <div className="relative items-center w-full text-base font-regular px-4 py-4 rounded-lg bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400 text-white flex">
                                <FontAwesomeIcon icon="triangle-exclamation" className="mr-2" />
                                Aucun produit n'a été trouvé
                            </div>
                        ) : 
                            <table className="w-full">
                                <thead>
                                <tr>
                                        {/* Le code produit est le champ "shortName" */}
                                        <th className="border-b text-left">Code produit</th>
                                        <th className="border-b text-left">Nom</th>
                                        <th className="border-b text-left">Valeur</th>
                                        <th className="border-b text-left">Description</th>
                                        <th className="border-b text-left">Catégorie</th>
                                        <th className="border-b text-left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {offers.map((offer) => (
                                        <tr key={offer.shortName}>
                                            <td>{offer.shortName}</td>
                                            <td>{offer.name}</td>
                                            <td>{offer.fideltyPointValue}</td>
                                            <td>{offer.description}</td>
                                            <td>{offer.category}</td>
                                            <td>
                                                <FontAwesomeIcon icon="pen" onClick={() => handleEdit(offer.id)} className="cursor-pointer" />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                    }
                </CardBody>
            </Card>
        </>
    );
}