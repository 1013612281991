import APIClient from "../APIClients";

export default class ProductsAPI {
    public static async products() {
        return APIClient.interceptor.get(`/product`);
    }

    public static async edit(
        data: {
            id: string, 
            name: string,
            shortName: string,
            fideltyPointValue: number,
            description: string,
            category: string,
        }) {
        return APIClient.interceptor.put(`/product`, data);
    }

    public static async delete(id: string) {
        return APIClient.interceptor.delete(`/product/${id}`);
    }

    public static async product(id: string) {
        return APIClient.interceptor.get(`/product/${id}`);
    }

    static categories() {
        return APIClient.interceptor.get(`/product/categories`);
    }

    public static async new(
        data: { 
            name: string,
            shortName: string,
            fideltyPointValue: number,
            description: string,
            category: string,
        }
    ) {
        return APIClient.interceptor.post(`/product`, data);
    }
}
