import { Card, CardBody, CardHeader, IconButton, Typography } from "@material-tailwind/react";
import { ProductsInterface } from "../../../interfaces/ProductsInterface";
import { useEffect, useState } from "react";
import ProductsAPI from "../../../services/API/clients/ProductsAPI";
import handleErrorResponse from "../../../hooks/HandleErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import StatisticsAPI, { GroupPeriodEnum } from "../../../services/API/clients/StatisticAPI";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import LineChart from "../../../components/ChartLines";
import { toast } from "react-toastify";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
  

export default function Statistics() {
    const [products, setProducts] = useState<ProductsInterface[]>([]);
    const [period, setPeriod] = useState<string>("DAY");
    const [selectedProduct, setSelectedProduct] = useState<ProductsInterface>();
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [statistics, setStatistics] = useState<number[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    const fetchProducts = async () => {
        const response = await ProductsAPI.products();
        if (response.status === 200 || response.status === 201) {
            setProducts(response.body);
            if (response.body.length > 0) {
                setSelectedProduct(response.body[0]);
                fetchStatistics(response.body[0].id);
            }
        } else {
            handleErrorResponse(response);
        }
    }

    const fetchStatistics = async (value?: string, isRandom = false) => {
        const data = {
            startDate: startDate !== "" ? startDate : moment().startOf('year').format('YYYY-MM-DD'),
            endDate: endDate !== "" ? endDate : moment().endOf('month').format('YYYY-MM-DD'),
            productId: (value && value !== "") ? value : selectedProduct?.id ?? "",
            groupPeriod: period as GroupPeriodEnum
        }

        const response = await StatisticsAPI.new(data);
        if (response.status === 200 || response.status === 201) {
            console.log(response.body)
            setStatistics(response.body.map((element: any) => element.total));
            setLabels(response.body.map((element: any) => element.period));
        } else {
            handleErrorResponse(response);
        }
    }

    useEffect(() => {
        setStartDate(moment().startOf('year').format('YYYY-MM-DD'));
        setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
        fetchProducts();
    }, []);

    const handleSelectedProduct = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const product = products.find((product) => product.id === e.target.value);
        setSelectedProduct(product);
    }

    return (
        <Card onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="w-full mt-12 shadow-none border-2 border-black rounded-sm">
            <CardHeader onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="gradient" color="purple" className="flex justify-center items-center px-8 h-16 place-items-center rounded-sm">
                <Typography onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="h3" color="white">
                Statistiques
                </Typography>
            </CardHeader>
            <CardBody onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="flex flex-col gap-4">
                <div className="flex justify-between items-center gap-4">
                    <select 
                        value={selectedProduct?.id} 
                        onChange={handleSelectedProduct}
                        className={`border rounded-lg p-2 w-full disabled:bg-gray-300`}
                        disabled={products.length === 0}
                    >
                        {products.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                    <select 
                        value={period} 
                        onChange={(e) => setPeriod(e.target.value)}
                        className={`border rounded-lg p-2 w-full disabled:bg-gray-300`}
                    >
                        <option value="DAY">
                            Jour
                        </option>
                        <option value="MONTH">
                            Mois
                        </option>
                        <option value="YEAR">
                            Année
                        </option>
                    </select>
                    <input
                        type="date"
                        placeholder="Points"
                        className={`border rounded-lg p-2 w-full`}
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                        type="date"
                        placeholder="Points"
                        className={`border rounded-lg p-2 w-full`}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <IconButton
                        color="purple"
                        onClick={() => fetchStatistics()}
                        className="text-white w-32"
                        placeholder=""
                        onPointerEnterCapture={() => console.log()}
                        onPointerLeaveCapture={() => console.log()}
                    >   
                        <FontAwesomeIcon icon="magnifying-glass" />
                    </IconButton>
                    <IconButton
                        color="purple"
                        onClick={() => fetchStatistics("", true)}
                        className="text-white w-32"
                        placeholder=""
                        onPointerEnterCapture={() => console.log()}
                        onPointerLeaveCapture={() => console.log()}
                    >   
                        <FontAwesomeIcon icon="random" />
                    </IconButton>
                </div>
                <LineChart data={statistics} labels={labels} />
            </CardBody>
        </Card>
    );
}
