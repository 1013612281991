import APIClient from "../APIClients";

export default class Fidelite {
    public static async offers() {
        return APIClient.interceptor.get(`/loyalty-point`);
    }

    public static async edit(data: { id: string; productId: string; point: number }) {
        return APIClient.interceptor.put(`/loyalty-point`, data);
    }

    public static async new(data: { productId: string; point: number }) {
        return APIClient.interceptor.post(`/loyalty-point`, data);
    }

    public static async delete(id: string) {
        return APIClient.interceptor.delete(`/loyalty-point/${id}`);
    }

    public static async offer(id: string) {
        return APIClient.interceptor.get(`/loyalty-point/${id}`);
    }
}
