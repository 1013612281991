import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, Input, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import ModalPub from "../../../components/Modals/ModalPub";
import { PublicityInterface } from "../../../interfaces/PublicityInterface";
import PubsAPI from "../../../services/API/clients/PubsAPI";
import handleErrorResponse from "../../../hooks/HandleErrors";

export default function Publicity() {
    const [pubs, setPubs] = useState<PublicityInterface[]>([]);
    const [idToEdit, setIdToEdit] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);

    const fetchDataPubs = async () => {
        const response = await PubsAPI.pubs();
        if (response.status === 200 || response.status === 201) {
            setPubs(response.body);
        } else {
            handleErrorResponse(response);
        }
    };

    useEffect(() => {
        fetchDataPubs();
    }, []);

    const handleEdit = async (id: string) => {
        setIdToEdit(id);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        fetchDataPubs();
        setIdToEdit("");
    };

    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            fetchDataPubs();
        } else {
            const dataFiltered = pubs.filter((pub) => pub.title.toLowerCase().includes(e.target.value.toLowerCase()) || pub.description.toLowerCase().includes(e.target.value.toLowerCase())); 
            setPubs(dataFiltered);
        }
    };

    return (
        <>
            <ModalPub open={openModal} setOpen={handleCloseModal} idToEdit={idToEdit} />
            <Card onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" className="w-full mt-12 shadow-none border-2 border-black rounded-sm">
                <CardHeader onPointerEnterCapture={() => console.log()} onPointerLeaveCapture={() => console.log()} placeholder="" variant="gradient" color="purple" className="flex justify-between items-center px-8 h-16 place-items-center rounded-sm">
                    <span></span>
                    <Typography placeholder="" onPointerLeaveCapture={() => console.log()} onPointerEnterCapture={() => console.log()} variant="h3" color="white">
                        Publicités
                    </Typography>
                    <button className="bg-white text-purple-500 px-4 py-2 rounded-lg" onClick={() => setOpenModal(true)}>
                        Nouveau
                    </button>
                </CardHeader>
                <CardBody placeholder="" onPointerLeaveCapture={() => console.log()} onPointerEnterCapture={() => console.log()} className="flex flex-col gap-4">
                <Input onPointerLeaveCapture={() => console.log()} onPointerEnterCapture={() => console.log()} crossOrigin="" label="Rechercher" placeholder="Rechercher" color="purple" onChange={handleSearch} />
                    {pubs.length === 0 ? (
                        <div className="relative items-center w-full text-base font-regular px-4 py-4 rounded-lg bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400 text-white flex">
                            <FontAwesomeIcon icon="triangle-exclamation" className="mr-2" />
                            Aucune publicité n'a été trouvée
                        </div>
                    ) : (
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="border-b text-left">Nom</th>
                                    <th className="border-b text-left">Description</th>
                                    <th className="border-b text-left">Image</th>
                                    <th className="border-b text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {pubs.map((pub) => (
                                    <tr key={pub.id}>
                                        <td>{pub.title}</td>
                                        <td>{pub.description}</td>
                                        <td>
                                            <img src={pub.url} alt="publicity" width={100} />
                                        </td>
                                        <td>
                                            <FontAwesomeIcon icon="pen" onClick={() => handleEdit(pub.id)} className="cursor-pointer" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </CardBody>
            </Card>
        </>
    );
}
