import APIClient from "../APIClients";

export enum GroupPeriodEnum {
    DAY = 'DAY',
    MONTH = 'MONTH',
    YEAR = 'YEAR'
}

export default class StatisticsAPI {
    public static async new(data: {
        // startDate: Date,
        // endDate: Date,
        startDate: string,
        endDate: string,
        productId: string,
        groupPeriod: GroupPeriodEnum
    }) {
        // const random = Math.floor(Math.random() * 50);
        // return { status: 200, body: { visiteCount: random } };
        
        return APIClient.interceptor.post(`/statistic/product`, data);
    }
}
