import axios from "axios";

export const fetchSuggestions = (inputValue: string) =>
    new Promise<[]>((resolve) => {
        if (inputValue.length < 3) {
            resolve([]);
            return;
        } else {
            addressAutocomplete(inputValue)
                .then((results) => {
                    resolve(
                        results.features
                            .filter(
                                (feature: any) =>
                                    (feature.properties.street || feature.properties.name) &&
                                    feature.properties.city &&
                                    feature.properties.postcode &&
                                    feature.properties.state &&
                                    feature.properties.country
                            )
                            .map((feature: any) => {
                                const housenumber = feature.properties.housenumber
                                    ? `${feature.properties.housenumber} `
                                    : "";
                                return {
                                    label: `${housenumber}${feature.properties.street || feature.properties.name
                                        }, ${feature.properties.city}, ${feature.properties.postcode}, ${feature.properties.state
                                        }, ${feature.properties.country}`,
                                    longitude: feature.geometry.coordinates[0],
                                    latitude: feature.geometry.coordinates[1]
                                };
                            })
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
    );

const addressAutocomplete = async (address: string, limit?: string) => {
    try {
        let url = `https://photon.mytimi.fr/api?q=${address}`;
        if (limit) {
            url += `&limit=${limit}`;
        }
        const response = await axios.get(url);
        if (response && response.data) {
            return response.data;
        } else {
            throw new Error("Invalid response from server");
        }
    } catch (error) {
        console.error(error);
    }
};