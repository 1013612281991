import { Dialog } from "@headlessui/react";
import ModalWrapper from "./ModalWrapper";
import { useEffect, useState } from "react";
import { ProductInterface } from "../../interfaces/FideliteInterface";
import ProductsAPI from "../../services/API/clients/ProductsAPI";
import handleErrorResponse from "../../hooks/HandleErrors";

const defaultData: ProductInterface = {
    id: "",
    productName: "",
    productShortName: "",
    productDescription: "",
    productCategory: "FOOD",
    point: 0,
} as const;

export default function ModalProduct({ open, setOpen, idToEdit }: Readonly<{ open: boolean; setOpen: (open: boolean) => void; idToEdit?: string }>) {
    const [categoryIsIncorrect, setCategoryIsIncorrect] = useState<boolean>(true);
    const [product, setProduct] = useState<ProductInterface>(structuredClone(defaultData));
    const [editedProduct, setEditedProduct] = useState<ProductInterface>(structuredClone(defaultData));
    const [categories, setCategories] = useState<string[]>([]);

    const getProduct = async (id: string) => {
        const response = await ProductsAPI.product(id);
        if (response.status === 200 || response.status === 201) {
            setProduct({
                id: response.body.id,
                productShortName: response.body.shortName,
                productName: response.body.name,
                productDescription: response.body.description,
                productCategory: response.body.category,
                point: response.body.fideltyPointValue
            })
            setEditedProduct({
                id: response.body.id,
                productShortName: response.body.shortName,
                productName: response.body.name,
                productDescription: response.body.description,
                productCategory: response.body.category,
                point: response.body.fideltyPointValue
            });
        } else {
            handleErrorResponse(response);
        }
    };

    const getProductCategories = async () => {
        const response = await ProductsAPI.categories();

        if (response.status === 200) {
            setCategories(response.body.categories);
            setProduct({...product, productCategory: categories[0]})
        } else {
            handleErrorResponse(response);
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCategoryIsIncorrect(true);

        if (idToEdit && idToEdit !== "" && editedProduct.id !== "") {
            const datas = {
                id: editedProduct.id,
                shortName: editedProduct.productShortName,
                name: editedProduct.productName,
                description: editedProduct.productDescription,
                category: editedProduct.productCategory,
                fideltyPointValue: editedProduct.point
            }
            const response = await ProductsAPI.edit(datas);

            if (response.status === 200 || response.status === 201) {
                setOpen(false);
            } else {
                handleErrorResponse(response);
                if (response.body.title === "Bad Request")
                    setCategoryIsIncorrect(false);
            }
        } else {
            createObjet();
        }
    };

    const createObjet = async () => {
        const datas = {
            shortName: editedProduct.productShortName,
            name: editedProduct.productName,
            description: editedProduct.productDescription,
            category: editedProduct.productCategory ?? 'FOOD',
            fideltyPointValue: editedProduct.point
        }

        const response = await ProductsAPI.new(datas);

        if (response.status === 200 || response.status === 201) {
            setOpen(false);
        } else {
            handleErrorResponse(response);
            if (response.body.title === "Bad Request")
                setCategoryIsIncorrect(false);
        }
    }

    useEffect(() => {
        getProductCategories();
    });


    useEffect(() => {
        if (idToEdit) {
            getProduct(idToEdit);
        } else {
            setProduct(structuredClone(defaultData));
            setEditedProduct(structuredClone(defaultData));
        }
        return () => {
            setProduct(structuredClone(defaultData));
        }
    }, [idToEdit]);

    const deleteMyObject = async () => {
        if (idToEdit && idToEdit !== "") {
            const response = await ProductsAPI.delete(idToEdit);

            if (response.status === 204) {
                setOpen(false);
            } else {
                handleErrorResponse(response);
            }
        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white px-16 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center">
                    {idToEdit ? "Modification d'un" : "Nouveau"} produit
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-6">
                    <input
                        type="text"
                        placeholder="Nom"
                        className="border rounded-lg p-2"
                        value={editedProduct?.productName}
                        onChange={(e) => setEditedProduct({ ...editedProduct, productName: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Code produit"
                        className="border rounded-lg p-2"
                        value={editedProduct?.productShortName}
                        onChange={(e) => setEditedProduct({ ...editedProduct, productShortName: e.target.value })}
                    />
                    <input
                        type="number"
                        placeholder="Valeur en point de fidelité"
                        className="border rounded-lg p-2"
                        value={editedProduct?.point}
                        onChange={(e) => setEditedProduct({ ...editedProduct, point: Number.parseInt(e.target.value) })}
                    />
                    <input
                        type="text"
                        placeholder="Description"
                        className="border rounded-lg p-2"
                        value={editedProduct?.productDescription}
                        onChange={(e) => setEditedProduct({ ...editedProduct, productDescription: e.target.value })}
                    />
                    <select
                        value={editedProduct?.productCategory}
                        onChange={(e) => setEditedProduct({ ...editedProduct, productCategory: e.target.value })}
                        className={`border rounded-lg p-2 ${categoryIsIncorrect ? "" : "border-red-500"}`}
                    >
                        {categories.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                    <div className="flex gap-4">
                        <button type="submit" className="rounded-full border bg-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans">
                            {idToEdit ? "Modifier" : "Ajouter"}
                        </button>
                        {idToEdit && (
                            <button type="button" onClick={() => deleteMyObject()} className="rounded-full border bg-red-300 text-white px-4 py-2 text-sm font-medium  hover:shadow-xl transition-all font-sans">
                                Suppression
                            </button>
                        )}
                    </div>
                </form>
            </Dialog.Panel>
        </ModalWrapper>
    );
}