import APIClient from "../APIClients";

export default class Shop {
    public static async user() {
        return APIClient.interceptor.get(`/shop`);
    }

    public static async edit(data: {
        email: string;
        password: string;
        name: string;
        address: string;
        latitude: number;
        longitude: number;
        phoneNumber: string;
        pointPerPricePercent: number;
    }) {

        console.log(data);
        return APIClient.interceptor.put(`/shop`, data);
    }

    public static async deleting() {
        return APIClient.interceptor.delete(`/shop`);
    }
}
