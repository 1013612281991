import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BorderBottom from "../../assets/bottomBorderWhite.png"
import LoginPicture from "../../assets/login_picture.png"
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons"
import { useState } from "react"
import { Storage } from "../../services/storage"
import Auth from "../../services/API/clients/AuthAPI"
import { Link } from "react-router-dom"
import handleErrorResponse from "../../hooks/HandleErrors"

export default function Login() {
    const [data, setData] = useState({
        email: "",
        password: ""
    })
    const [isVisible, setIsVisible] = useState(false)

    const handleChange = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        const response = await Auth.login(data);
        if (response.status === 200 || response.status === 201) {
            const token = response.body.accessToken;

            console.log(token);
            Storage.setToken(token);
            window.location.href = "/dash/products";
        } else {
            handleErrorResponse(response);
        }
    }

    return (
        <div className="min-h-screen w-screen flex justify-center items-center">
            <div className="w-full md:w-1/3 h-fit px-4 py-2">
                <img src={LoginPicture} alt="Login" className="mx-auto" />
                <div className="w-full h-fit px-4 py-2 border-[3px] rounded-t-sm bg-white border-black">
                    <div className="w-full h-16 flex justify-center items-center">
                        <h1 className="text-2xl font-bold">Connexion</h1>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="at" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input value={data.email} onChange={handleChange} name="email" type="text" placeholder="Adresse mail" className="w-full h-10 border border-black rounded pl-8" required />
                        </div>
                        <div className="w-full h-16 flex justify-center items-center relative">
                            <FontAwesomeIcon icon="lock" className="absolute left-2 top-0 bottom-0 m-auto text-black" />
                            <input value={data.password} onChange={handleChange} name="password" type={isVisible ? "text" : "password"} placeholder="Mot de passe" className="w-full h-10 border border-black rounded px-8" required />
                            <FontAwesomeIcon icon={isVisible ? "eye-slash" : "eye"} onClick={() => setIsVisible(!isVisible)} className="absolute right-2 top-0 bottom-0 m-auto text-black cursor-pointer hover:text-purple-500" />
                        </div>
                        <hr className="border-dashed border-black mt-2 mb-3" />
                        <div className="w-full h-16 flex justify-center items-center">
                            <button type="submit" className="w-full rounded h-10 uppercase bg-black text-white font-bold hover:rounded-lg hover:text-purple-500 transition-all">Se connecter</button>
                        </div>
                    </form>
                    <hr className="border-dashed border-black mt-3 mb-3" />
                    <span className="text-black hover:text-gray-700 transition-all text-xs text-center w-full flex mt-2 justify-center items-center cursor-pointer">Vous n'avez pas encore de compte ?</span>
                    <div className="w-full h-16 flex justify-center items-center">
                        <Link to="/signup" className="w-full rounded h-10 uppercase border border-black text-black font-bold flex items-center justify-center bg-white hover:rounded-lg hover:text-purple-500 transition-all">INSCRIPTION</Link>
                    </div>
                </div>
                <div className="w-full h-4 flex justify-center items-center">
                    <img src={BorderBottom} alt="Border Bottom" />
                </div>
            </div>
        </div>
    )
}