import APIClient from "../APIClients";

export default class PubsAPI {
    public static async pubs() {
        return APIClient.interceptor.get(`/advertisement`);
    }

    public static async pub(id: string) {
        return APIClient.interceptor.get(`/advertisement/${id}`);
    }

    public static async edit(
        data: {
            id: string,
            title: string,
            description: string,
            url: string
        }
    ) {
        return APIClient.interceptor.put(`/advertisement`, data);
    }

    public static async new(
        data: {
            title: string,
            description: string,
            url: string
        }
    ) {
        return APIClient.interceptor.post(`/advertisement`, data);
    }

    public static async delete(id: string) {
        return APIClient.interceptor.delete(`/advertisement/${id}`);
    }
}
